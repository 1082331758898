import React from 'react';

import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import EBooks from './components/EBooks/EBooks';
import Footer from './components/Footer/Footer';
function App() {
  const { pathname } = useLocation();

  return (
    <div className="h-screen w-screen flex flex-col" >
      <Header />

      <div className="justify-start h-screen w-screen flex flex-col px-4 my-4" >

        <Routes>

          < Route path="" element={<EBooks />} />

        </Routes>
      </div>
      <Footer />

    </div>
  );
}

export default App;
