// const marcaSinFondo =  require("./marcaSinFondo04.png")

import React from 'react';
import './Header.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router';
const marcaSinFondo=  require("../../assets/cardozoMarca/formats/PNG/marcaSinFondo08.png");

function Header() {
    const dispatch = useAppDispatch();

    return (

        <header className="flex w-full bg-white header gap-x-2 text-white items-center w-full px-4 py-2">
            <div>
            <img className='logoHeader' src={marcaSinFondo} alt="Logo" />
            </div>
            
            <div className='flex flex-start  justify-start'>
                <h1>Julio Cardozo Personal Trainer</h1>
            </div>
        </header>
    );
}

export default Header;