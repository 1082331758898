import { useState } from "react";

const guiaNutricionCover = require("../../assets/cardozoMarca/guiaNutricionCover.jpeg");

interface IFitbook {
    id: string;
    description: string;
    url: string;
    cost: number;

};

const environment = {
    integrationUrl: 'http://cobrashields.com/integracion.html'
}
function EBooks() {
    const [fitbooks, setFitbooks] = useState<Array<IFitbook>>([{
        id: '1',
        description: 'Guía nutricional',
        url: guiaNutricionCover,
        cost: 40000
    }]);

    const goToPayLink = async (fitbook: IFitbook) => {
        const MONTHLY_PAY_ID = 1;

        const ID_USUARIO = 1;

        const ID_BILLETERA = 1;

        const FECHA_INICIO = '2023-01-01';

        const FECHA_FIN = '2023-01-01';

        const NRO_TOKENS = 0;

        const URL_TO_CALL =
            `${environment.integrationUrl}?valor=${fitbook.cost}&codigoFactura=${fitbook.id}&descripcion=${fitbook.description}&campoExtra1=${MONTHLY_PAY_ID}&campoExtra2=FITBOOKS&campoExtra3=${ID_USUARIO}&campoExtra4=${ID_BILLETERA}&campoExtra5=${FECHA_INICIO}&campoExtra6=${FECHA_FIN}&campoExtra7=${NRO_TOKENS}`;

        console.log('url', URL_TO_CALL);
        window.location.replace(URL_TO_CALL);

    }


    return (<div>
        <div className="flex flex-col my-2 mx-2">
            {
                fitbooks?.map((fitbook: IFitbook) => (
                    <img className='h-60 w-48   flex flex-col' src={fitbook.url} alt="Logo" key={fitbook.id} onClick={() => { goToPayLink(fitbook) }} />
                    /*
                    <div className="h-48 w-36  border flex flex-col" key={fitbook.id} onClick={() => { goToPayLink(fitbook) }}>
                        <div>
                            {fitbook.description}
                        </div>

                    </div>
                    */
                ))}

        </div>


    </div>)
}
export default EBooks;